
import { CourseData } from "@planetadeleste/vue-mc-learning";
import { Component, Prop, Vue } from "vue-property-decorator";
import CourseThumb from "@/modules/courses/components/course/CourseThumb.vue";
import CourseDuration from "@/modules/courses/components/course/CourseDuration.vue";
import CourseRating from "@/modules/courses/components/course/CourseRating.vue";
import CourseTitle from "@/modules/courses/components/course/CourseTitle.vue";
import CourseSummary from "@/modules/courses/components/course/CourseSummary.vue";
import CourseBtn from "@/modules/courses/components/course/CourseBtn.vue";
import { get } from "lodash";
import { OfferData } from "@planetadeleste/vue-mc-shopaholic";
import { diffPercent } from "@/services/helpers";
import AddToCart from "@/components/cart/AddToCart.vue";

@Component({
  components: {
    AddToCart,
    CourseThumb,
    CourseDuration,
    CourseRating,
    CourseTitle,
    CourseSummary,
    CourseBtn,
  },
})
export default class CourseCard extends Vue {
  @Prop(Object) readonly item!: CourseData;
  @Prop(Boolean) readonly hideSummary!: boolean;
  @Prop(Boolean) readonly titleInContent!: boolean;

  get offer(): OfferData | null {
    return get(this.item, "product.offers.0", null);
  }

  get price(): string | null {
    if (!this.offer) {
      return null;
    }

    return `${this.offer.currency} ${this.offer.price}`;
  }

  get fPrice(): number | null {
    if (!this.offer) {
      return null;
    }

    return this.offer.price_value;
  }

  get oldPrice(): string | null {
    if (!this.offer) {
      return null;
    }

    return `${this.offer.currency} ${this.offer.old_price}`;
  }

  get fOldPrice(): number | null {
    if (!this.offer) {
      return null;
    }

    return this.offer.old_price_value;
  }

  get sFreePercent(): string | null {
    if (!this.fPrice || !this.fOldPrice) {
      return null;
    }

    const sSymb = this.fOldPrice > this.fPrice ? "-" : "";

    return `${sSymb}${diffPercent(this.fOldPrice, this.fPrice)}%`;
  }

  get free(): boolean {
    return get(this.item, "free", false);
  }
}
