
import { CourseData } from "@planetadeleste/vue-mc-learning";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Location } from "vue-router";

@Component
export default class CourseTitle extends Vue {
  @Prop(Object) readonly item!: CourseData;
  @Prop(Boolean) readonly center!: boolean;

  get to(): Location | null {
    return !!this.item && this.item.id
      ? { name: "courses.view", params: { slug: this.item.slug } }
      : null;
  }

  get css(): Record<string, boolean> {
    return {
      course__title: true,
      "text-center": this.center,
    };
  }
}
