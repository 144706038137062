
import { CourseData } from "@planetadeleste/vue-mc-learning";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Location } from "vue-router";

@Component
export default class CourseBtn extends Vue {
  @Prop(Object) readonly item!: CourseData;
  @Prop({ type: String, default: "course.btn.label" }) readonly label!: string;

  get to(): Location | null {
    return !!this.item && this.item.id
      ? { name: "courses.view", params: { slug: this.item.slug } }
      : null;
  }
}
