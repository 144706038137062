
import { CourseData } from "@planetadeleste/vue-mc-learning";
import { BrandData, ProductData } from "@planetadeleste/vue-mc-shopaholic";
import { get } from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Location } from "vue-router";
import CornerRibbon from "@/components/common/CornerRibbon.vue";
import CourseTitle from "@/modules/courses/components/course/CourseTitle.vue";
import CoursePriceBlock from "@/modules/courses/components/course/CoursePriceBlock.vue";

@Component({
  components: { CoursePriceBlock, CourseTitle, CornerRibbon },
})
export default class CourseThumb extends Vue {
  @Prop(Object) readonly item!: CourseData;
  @Prop(Boolean) readonly list!: boolean;
  @Prop(String) readonly ribbon!: string;
  @Prop(Boolean) readonly hideTitle!: boolean;

  get css(): Record<string, any> {
    return {
      "course__thumb-list": this.list,
    };
  }

  get to(): Location | null {
    return !!this.item && this.item.id
      ? { name: "courses.view", params: { slug: this.item.slug } }
      : null;
  }

  get product(): Partial<ProductData> | null {
    return get(this.item, "product", null);
  }

  get brand(): Partial<BrandData> | null {
    return this.product ? get(this.product, "brand", null) : null;
  }

  get label(): string | undefined {
    return this.brand ? this.brand.name : undefined;
  }

  get free(): boolean {
    return get(this.item, "free", false);
  }
}
